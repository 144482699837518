// swiperImages
const swiperImages = [
    {
        id: 0,
        src: require("../Assets/Images/LandingSwiper/Բժիշկներ.jpg")
    },
    {
        id: 1,
        src: require("../Assets/Images/LandingSwiper/Բժշկի օգնականներ.jpg")
    },
    // {
    //     id: 1,
    //     src: require("../Assets/Images/LandingSwiper/team_1.jpeg")
    // },
    // {
    //     id: 2,
    //     src: require("../Assets/Images/LandingSwiper/team_2.jpeg")
    // },
    {
        id: 3,
        src: require("../Assets/Images/LandingSwiper/team_3.jpeg")
    }
];

export function getSwiperImages() {
    return swiperImages;
}
