const servicesInfo = [
    {
        id: 0,
        title: "Խորհրդատվություն",
        imageSrc: require("../Assets/Images/Services/consultation_white.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p><b>Խորհրդատվություն</b></p>
                  <br />
              </div>
              <p>
                  Ի՞նչ ակնկալիքներ ունի պացիենտը, երբ որոշում է դիմել բժիշկ-ստոմատոլոգի խորհրդատվության:
              </p>
              <p>
                  Թվում է, թե կարելի է մի քանի րոպեների ընթացքում ներկայացնել խնդիրը, իմանալ բժիշկի կարծիքը և հեռանալ
                  բավարարված կամ ոչ այնքան, իսկ իրականում որքան ինֆորմացիա է ստանում պացիենտը` այցելելով ստոմատոլոգիական
                  կլինիկա:
              </p>
              <ol>
                  <li>Ծանոթանում է կլինիկայի ադմինիստրատորի հետ, ով ներկայացնում է կլինիկայի կարգ ու կանոնը, օգնում է
                      լրացնել բժշկական քարտը, կազմակերպում է բժիշկի և պացիենտի հանդիպումները, զբաղվում է բոլոր
                      կազմակերպչական հարցերով:
                  </li>
                  <li>Ծանոթանում է կլինիկայի ընդհանուր մթնոլորտին, բուժ. անձնակազմին՝ մարդկանց, ում օգնությամբ
                      իրագործվում
                      է բոլոր բժշկական միջամտությունները:
                  </li>
                  <li>
                      Ծանոթանում է կլինիկայի սանիտարա-հիգիենիկ վիճակին, ցանկության դեպքում բուժ. քույրերի օգնությամբ
                      կազմակերպվում է շրջայց, որի ընթացքում պացիենտը կարող է ծանոթանալ բժշկական գործիքների ախտահանման
                      գործընթացին, սարքավորումներին, գործող կանոններին:
                  </li>
                  <li>Ծանոթանում է կլինիկայում կատարված ստոմատոլոգիական աշխատանքներին լուսանկարների միջոցով:</li>
              </ol>
              <br />
              <p> Ի՞նչ փուլերից է բաղկացած բժշկական խորհրդատվությունը:</p>
              <ol>
                  <li>
                      <p>Պացիենտը մանրակրկիտ ներկայացնում է իր գանգատները, կատարվում է վիճակի օբյեկտիվ քննություն և
                          կատարվում
                          են բոլոր անհրաժեշտ հետազոտությունները, այդ թվում ռենտգեն ախտորոշում (OPTG ), անհրաժեշտության
                          դեպքուն
                          համակարգչային տոմոգրաֆիա, ախտորոշում ներբերանային տեսախցիկի օգնությամբ, որի ընթացքում պացիենտը
                          մոնիտորի վրա կարող է տեսնել այնպիսի մանրամասներ, որոնք անզեն աչքով տեսանելի չեն:</p>
                      <p>Ըստ անհրաժեշտության պացիենտը հնարավոր է անցնի լրացուցիչ հետազոտություններ:</p>
                  </li>
                  <li>
                      Կատարվում է պրոֆեսիոնալ ֆոտոնկարահանում, որի ընթացքում պացիենտը նկարահանվում է մի քանի
                      դիտանկյուններից, այդ նկարները օգտագործվում են թե ախտորոշման, բուժման, բուժման պլանը կազմելու,
                      անհրաժեշտության դեպքում լրացուցիչ խորհրդատվության համար, նաև մինչև - հետո արդյունքը գնահատելու
                      նպատակով:
                  </li>
                  <li>
                      Կազմակերպվում է լրացուցիչ խորհրդատվություն նեղ մասնագետների հետ: Խորհրդատվությանը կարող են
                      մասնակցել
                      միաժամանակ մի քանի նեղ մասնագետներ (իմպլանտոլոգ, էնդոդոնտիստ, օրթոդոնտ):
                  </li>
              </ol>
              <br />
              <p>
                  &emsp;Բժշկական խորհրդատվության ամբողջական լինելը հիմք է հանդիսանում ճշգրիտ ախտորոշման և հետևաբար
                  գրագետ
                  բուժման պլան առաջարկելու համար: Խորհրդատվությունից հետո կազմակերպվում է ներկլինիկական բժշկական
                  կոնսիլիում, երբ տվյալ իրավիճակը մանրամասնորեն և բազմակողմանի հետազոտված և քննարկված է, պացիենտին
                  առաջարկվում է բուժման պլան, որում հաշվի են առնված կատարվելիք աշխատանքի ծավալը, հերթականությունը ըստ
                  կարևորության և անհրաժեշտության:
              </p>
              <p>
                  &emsp; Անհրաժեշտ է նշել, որ պացիենտին բացի հիմնական բուժման պլանից առաջարկվում է ալտերնատիվ տարբերակ
                  (էկոնոմ):
                  Առաջին տարբերակը ենթադրում է նորարարական տեխնոլոգիաների կիրառումը, հաճախ այն լինում է ավելի ծախսատար:
                  Երկու տարբերակում էլ ներկայացվում է բուժման արժեքը, նաև փուլերի բաժանված վճարման պլան:
              </p>
              <p>
                  &emsp;Շատ հաճախ պացիենտը տեղյակ է լինում իր ունեցած խնդիրների փոքր մասից, բժիշկի խնդիրն է բացահայտել
                  բոլոր
                  թվացյալ փոքր, սակայն հաճախ լուրջ հետևանքների դրդապատճառ հանդիսացող խնդիրները, այսպես կոչված ջրի երես
                  բերել բոլոր ստորջրյա քարերը:
              </p>
              <p>
                  &emsp;Պետք է առանձնացնել մարդկային գործոնը, որպես կարևորագույն և անփոխարինելի բժշկական խորհրդատվության
                  և
                  առհասարակ գրագետ բուժում իրականացման գործընթացում: Այո, շատ կարևոր է կլինիկայի հագեցվածությունը
                  գերժամանակակից սարքավորումներով, կարևոր է նաև նորարական նյութերի և տեխնոլոգիաների կիրառումը, քանի որ
                  ստոմատոլոգիական ոլորտում զարգացման արագությունը չափազանց բարձր է և ժամանակակից բարձր պրոֆեսիոնալիզմը
                  անդադար կրթություն և վերապատրաստում է ենթադրում: Եվ միայն բժիշկն է հանդիսանում այն միջնորդը, որը այդ
                  նորարարությունը կարող է կիրառելի դարձնել պացիենտի համար: Բժիշկն է, որ օգտագործելով իր տարիների փորձը,
                  գիտելիքները, բժշկական ինտուիցիան կարող է առաջարկել համապատասխան բուժման պլան և իրագործել այն:
                  Առանձնահատուկ նշանակություն ունի բժիշկի կարողությունը տվյալ իրավիճակը մանրամասնորեն ներկայացնելը
                  պացիենտի համար հասկանալի լեզվով, հնարավորինս շրջանցելով մասնագիտական տերմինալոգիան: Ոչ պակաս կարևոր է
                  անձնական հատկանիշները, դիմացինին լսելու և խորապես հասկանալու ունակությունը, պացիենտի հոգեբանական և այլ
                  առանձնահատկությունները բացահայտելու կարողությունը:
              </p>
              <p>
                  &emsp;Պատահաբար չէ, որ փիլիսոփաներից մեկն ասել է. "Եթե բժշկի հետ զրույցից հետո պացիենտի վիճակը չի
                  թեթևանում,
                  ապա նա բժիշկ չէ"
              </p>
          </div>

    },
    {
        id: 1,
        title: "Դիագնոստիկ Ստոմատոլոգիա",
        imageSrc: require("../Assets/Images/Services/diagnostic_dentistry.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p>
                      <b>Դիագնոստիկ Ստոմատոլոգիա</b>
                  </p>
                  <br />
              </div>
              <ul>
                  <li>Ռենտգեն նկարահանում</li>
                  <li>Ախտորոշում ներբերանային տեսախցիկի միջոցով</li>
              </ul>
              <br />
              <p>
                  "Ինդիվիդում" ստոմատոլոգիական կլինիկայում բժշկության առանցքային սկզբունքի գործադրումով ոչ թե
                  հիվանդությունը, այլ հիվանդին են բուժում` յուրաքանչյուր անձին` անհատական մոտեցում, յուրաքանչյուր
                  խնդրին` անհատական լուծում նշանաբանով:
              </p>
          </div>
    },
    {
        id: 2,
        title: "Էսթետիկ Ստոմատոլոգիա",
        imageSrc: require("../Assets/Images/Services/aesthetics.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}><p><b>Էսթետիկ Ստոմատոլոգիա</b></p></div>
              <br />
              <p>Պացիենտների գեղեցիկ ժպիտ ունենալու ակնկալիքների աճի հետ մեկտեղ զգալի վերելք է ապրել ստոմատոլոգիայի
                  էսթետիկ ճյուղը: Էսթետիկ ստոմատոլոգիան գիտության և արվեստի համադրություն է: Կարելի է ասել, որ այն
                  առանձին ճյուղ չէ, այլ ստոմատոլոգիայի բոլոր ճյուղերի համադրում: Մեր մասնագետների թիմը այստեղ է,
                  որպեսզի ուղեկցի Ձեզ լավագույն արդյունքի հասնելու ճանապարհին` հաշվի առնելով գեղագիտական, ֆունքցիոնալ
                  և կենսաբանական տեսանկյունները:
              </p>
              <p>
                  Մենք այստեղ ենք, որպեսզի տրամադրենք Ձեզ անհատականացված մոտեցում: Էսթետիկ ստոմատոլոգիայի զարգացման
                  հետ
                  մենք տրամադրում ենք Ձեզ հետևյալ ծառայությունները Ձեր բնական ժպիտը վերականգնելու համար.
              </p>
              <ul>
                  <li>Ժպիտի դիզայն</li>
                  <li>Ատամների սպիտակեցում</li>
                  <li>Բերանի խոռոչի պրոֆեսիոնալ հիգիենա և ատամների փայլեցում</li>
                  <li>Ատամների էսթետիկ վերականգնում` ուղղակի և անուղղակի վերականգնման միջոցով</li>
                  <li>Վինիրներ</li>
                  <li>Ներդիրներ` ինլեյ և օնլեյ</li>
              </ul>
              <br />
              <p><b>Ատամների սպիտակեցում</b></p>
              <p>
                  Մեր կլինիկան պատրաստ է տրամադրել Ձեզ ատամների սպիտակեցման երկու տարբերակ` օֆիսային և տնային:
                  Սպիտակեցման
                  երկու մեթոդներն էլ անվնաս են ատամների էմալի համար և օգտագործվող նյութերը հաստատված են Ամերիկայի
                  Ստոմատոլոգների Ասոցիացիայի կողմից: Երկու տեսակի պրոցեդուրայի ժամանակ կիրառվող նյութերը պարունակում են
                  ջրածնի պերօքսիդ տարբեր կոնցենտրացիաներով: Օֆիսային սպիտակեցումը կատարվում է ստոմատոլոգի կոզմից:
                  Պրոցեդուրան տևում է մոտավորապես 60 րոպե, կախված Ձեր ատամների բնական գույնից և ցանկալի ստացման երանգից:
                  Տնային սպիտակեցման համար ստոմատոլոգը տրամադրում է Ձեզ անհատականացված կապպաներ և սպիտակեցման քսուքը
                  տնային պայմաններում կիրառելու համար:
              </p>
              <br />
              <p><b>Ատամների էսթետիկ վերականգնում</b></p>
              <p>
                  Ատամների ուղիղ էսթետիկ վերականգնումը լայն տարածում ստացած գործընթաց է, որի արդյունքում ատամների
                  վերականգնումը կատարվում է ժամանակակից պլոմբանյութերով: Ուղիղ վերականգնումները կատարվում են մեր
                  պրոֆեսիոնալ մասնագետների կողմից, որոնք տիրապետում են պլոմբանյութերի հետ աշխատանքային նյուանսներին:
                  Պրոցեդուրան կատարվում է վերականգնելով ատամի բոլոր շերտերը, թափանցիկությունը, ձևը և ֆունքցիան:
                  Պլոմբանյութերով ատամների վերականգնումները հասանելի են բոլոր ատամների համար:
              </p>
              <br />
              <p><b>Վինիրներ</b></p>
              <p>
                  Վինիրները անհատական պատրաստված բարակ թիթեղներ են, որոնք վերականգնում են ատամների գեղագիտական տեսքը:
                  Վինիրները կարող են պատրաստվել անմիջապես բերանի խոռոչում օգտագործելով պլոմբանյութեր կամ ստոմատոլոգիական
                  լաբորատորիայում օգտագործելով կերամիկական նյութեր: Կախված Ձեր անհատական դեպքից, մեր պրոֆեսիոնալ
                  մասնագետը
                  կօգնի Ձեզ ընտրել համապատասխան վինիրների տեսակը բարենպաստ արդյունք ստանալու համար: Վինիրներն
                  օգտագործվում
                  են հետևյալ ատամների վերականգնման համար
              </p>
              <ul>
                  <li>Գունափոխված ատամներ</li>
                  <li>Մաշված ատամներ</li>
                  <li>Ճաքած կամ կոտրված ատամներ</li>
                  <li>Սխալ դիրք ունեցող ատամներ</li>
                  <li>Տեսանելի տարածոիթյունների առկայություն հարևան ատամներ միջև</li>
              </ul>
              <p>
                  Խորհրդատվության ժամանակ մեր պրոֆեսիոնալ մասնագետը կտրամադրի Ձեզ համապատասխան ախտորոշումը և բուժման
                  պլանը`
                  վինիրներ ունենալու մանրամասն քայլերով:
              </p>
              <br />
              <p><b>Ներդիրներ</b></p>
              <p>
                  Սովորաբար, ատամների բնական հյուսվածքների մեծ քանակի քայքայվածությունը (ավելի քան 50% ) համարվում է
                  հակացուցում պլոմբանյութերով ատամի վերականգնման համար: Այս դեպքում, ներդիրնեը համարվում են իդեալական
                  տարբերակ ատամների տեսքը և ֆունքցիան վերականգնելու համար: Ներդիրները պատրաստվում են լաբորատորիայում
                  կերամիկական նյութերից: Ներդիրների դրական կոմերն են
              </p>
              <ul>
                  <li>Թույլ են տալիստ ստոմատոլոգին պահպանել ատամի բնական հյուսվածքի մեծ քանակ</li>
                  <li>Դրանք երկարակյաց են` պատրաստված կարծր նյութից</li>
                  <li>Ամրեցնում են ատամը մինչև 75%</li>
                  <li>Երկարացնում են ատամի կյանքը</li>
                  <li>Կանխարգելում են հավելյալ միջամտությունները</li>
              </ul>
          </div>
    },
    {
        id: 3,
        title: "Ատամների Սպիտակեցում",
        imageSrc: require("../Assets/Images/Services/teeth_whitening.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p>
                      <b>Սպիտակեցում</b>
                  </p>
              </div>
              <br />
              <p>
                  Ժամանակակից ստոմատոլոգիան առաջարկում է սպիտակեցման բազմաթիվ եղանակներ: Յուրաքանչյուրն, ով ուզում է
                  ունենալ շլացուցիչ ժպիտ կարող է գտնել համապատասխան և մատչելի սիտակեցման մեթոդ: Ատամնաքարերի և
                  ատամնափառի
                  պրոֆեեսիոնալ մաքրումը անգամ կարող է զգալիորեն փոխել ատամների գույնը, իսկ պրոֆեսիոնալ սպիտակեցման
                  միջոցով
                  Դուք կարող եք ձյունաճերմակ դարձնել Ձեր ժպիտը: Ամեն դեպքում բժիշկ-ստոմատոլոգի օգնությամբ Դուք կարող եք
                  գտնել Ձեր համար լավագույն տարբերակը:
              </p>
              <br />
              <p>
                  <b> Օֆիսային սպիտակեցում</b>
              </p>
              <p>
                  Մեթոդի էությունը կայանում է նրանում, որ ատամները պատվում են սպիտակեցնող գելով, որը պարունակում է ակտիվ
                  կոմպոնենտներ: Նախապես փափուկ հյուսվածքները պաշտպանվում են այրվածքից խուսափելու նպատակով: Ակտիվ գելը
                  մնում է ատամների վրա 30-60րոպե, այդ ընթացքում ակտիվ թթվածինը փոխում է էմալի գույնը: Մեթոդը ցուցված չէ,
                  եթե ատամների վրա առկա են բազմաթիվ ճաքեր: Դեղնավուն էմալի պարագայու արդյունքը ավելի տպավորիչ կլինի, քան
                  մոխրագույն երանգի դեպքում: Ցանկալի արդյունքի համար հնարավոր է կրկնել միջամտությունը մի քանի անգամ:
              </p>
              <br />
              <p>
                  <b> Տնային սպիտակեցում</b>
              </p>
              <p>
                  Տնային սպիտակեցում իրականացնելու նպատակով նախապես պատրաստվում են թափանցիկ և էլաստիկ կապպաներ, որոնք
                  լիաժեք համապատասխանում են պացիենտի ատամներին: Սպիտակեցման գելը կապպայի օգնությամբ հավասարաչափ
                  տարածվում
                  է ատամների մակերեսով և մնում այնտեղ մինչև պրոցեդուրայի ավարտը: Տևողությունը կարող է լինել տարբեր
                  կախված
                  մեթոդի ընտրությունից 8-ից 14 օրերի ընթացքում:
              </p>
              <br />
              <p>
                  <b> Լազերային սպիտակեցում</b>
              </p>
              <p>
                  Փափուկ հյուսվածքները պաշտպանելուց հետո, ատամները պատվում են սպիտակեցնող գելով: Գելի ակտիվացումը
                  կատարվում է լազերային ճառագայթի միջոցով, այդ ընթացքում արձակված ակտիվ նյութերը թափանցում են ատամների
                  էմալի և դենտինի խորը շերտերի մեջ և փոխում նրանց գույնը: Պրոցեդուրայի տևողությունը 30-ից 40րոպե է:
              </p>
              <p>
                  &emsp; Կարևոր է իմանալ, որ առաջնահերթ է սպիտակեցումից առաջ կատարել բերանի խոռոչի ամբողջական սանացիա,
                  արն
                  ենթադրում է անհրաժեշտության դեպքում լնդերի բուժում, հին ռեստավրացիաների փոխում, կարիեսների բուժում:
                  Նաև
                  անհրաժեշտ է կատարել ատամնափառի և ատամնաքարեի պրոֆեսիոնալ հեռացում, որը նախապատրաստում է ատամները
                  սպիտակեցման պրոցեդուրային: Ատամների սպիտակեցում կատարելիս անհրաժեշտ է մի քանի օրերի ընթացքում խուսափել
                  գունավոր սննդից (գինի, թեյ, սուրճ, գունավոր մրգեր, հատապտուղներ):
              </p>
          </div>
    },
    {
        id: 4,
        title: "Բրեկետներ",
        imageSrc: require("../Assets/Images/Services/orthodontics.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p>
                      <b>Բրեկետներ</b>
                  </p>
              </div>
              <br />
              <p>
                  Եթե Ձեր ատամնաշարը, կծվածքը Ձեզ չի գոհացնում այցելեք Մեր կենտրոն և Ձեր ատամնաշարի գեղեցիկ էսթետիկ
                  տեսքը ապահովված է: Որպես կանոն բրեկետները կրում են 1.5- 2 տարի, որից հետո անթերի, շլացուցիչ ժպիտը
                  պատրաստ է : Ամենակարևոր պայմաններից է ճիշտ մասնագետի՝ օրթոդոնտի ընտրությունը, բրեկետ կրողի համար
                  համբերատարությունը և բրկետների ճիշտ խնամքը՝ բրեկետ-համակարգի առկայության դեպքում դուք ստիպված եք ամեն
                  սնունդ ընդունելուց հետո լավ լվանալ ատամները, իսկ խնամքի մասին մանրամասն տեղեկատվությունն արդեն Ձեզ
                  կտրամադրի Ձեր ատամնաբույժը:
              </p>
          </div>
    },
    {
        id: 5,
        title: "Օրթոպեդիկ Ստոմատոլոգիա ",
        imageSrc: require("../Assets/Images/Services/prosthetics.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p>
                      <b>ՕՐԹՈՊԵԴԻԿ ՍՏՈՄԱՏՈԼՈԳԻԱ</b>
                  </p>
              </div>
              <br />
              <ul>
                  <li>
                      շարժական պրոթեզավորում (թիթեղային, բյուգելային,պլաստիկ, միկրոպրոթեզներ)
                  </li>
                  <li>
                      անշարժ պրոթեզավորում (մետաղ կերամիկական, կերամիկական ցիրկոնի օքսիդի հիմքի վրա, շապիկներ և
                      կամուրջներ, վինիրներ)
                  </li>
              </ul>
              <br />
              <p>
                  &emsp;
                  Հիշեք, որ պրոֆեսիոնալ մասնագետի և ժամանակակից տեխնոլոգիաներով հագեցած կլինիկայի ճիշտ ընտրությունը Ձեր
                  առողջ ժպիտի գրավականն է
              </p>
          </div>
    },
    {
        id: 6,
        title: "Թերապևտիկ Ստոմատոլոգիա",
        imageSrc: require("../Assets/Images/Services/therapeutic_dentistry.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p>
                      <b>Թերապևտիկ ստոմատոլոգիա</b>
                  </p>
              </div>
              <br />
              <p>
                  Ստոմատոլոգիայի գլխավոր ուղղություններից մեկը՝ թերապևտիկ ստոմատոլոգիան զբաղվում է ատամների
                  հիվանդությունների բուժումով և պրոֆիլակտիկայով:
              </p>
              <p>
                  &emsp;
                  Բուժումը, ատամների վերականգնումը ժամանակակից թերապևտիկ ստոմատոլոգիայի այն օղակը, որը իր մեջ է ներառում
                  տվյալ ուղղության բոլոր նորարարությունները ու գյուտերը:
              </p>
              <p>
                  &emsp;
                  Թերապևտիկ ստոմատոլոգիայի խնդիրը հանդիսանում է
                  ատամների բուժումը ցանկացած աստիճանի քայքայվածության և վնասվածության պարագայում՝ կարիեսի, ոչ կարիոզ
                  ախտահարումների և նրանց բարդությունների բուժում, ստոմատիտների բուժում, կոտրվածքների և ցանկացած այլ
                  վնասվածքների վերականգնում և այլն:
              </p>
              <p>
                  &emsp;
                  Թերապևտիկ ստոմատոլոգիան այժմ այնպիսի ակարդակի վրա է գտնվում, որ պրակտիկորեն կարող է փրկել ցանկացած
                  ատամ: Էնդոդոնտիայի զարգացման հետ մեկտեղ նվազում են ատամի հեռացման ցուցումները:
              </p>
              <p>
                  &emsp;
                  Էնդոդոնտիան զբաղվում է ատամի արմատուղիների բուժմամբ և վերականգնմամբ: Ատամի բուժումը հաջող կհամարվի
                  միայն այն դեպքում, երբ վերականգնված կլինի ատամի ֆունկցիան:
              </p>
              <p>
                  &emsp;
                  Ատամի վերականգնումն ու ռեստավրացիան թերապևտիկ ստոմատոլոգիայի հիմնական ուղղություններից մեկն է: Այսօր
                  հնարավոր է շատ հստակ վերականգնել ատամի բնական ձևը, այդքանի հետ մեկտեղ պահպանելով նրա բնական գույնը:
              </p>
              <p>
                  Մեր օրերում կիրառվում են բոլոր ժամանակակից նյութերն ու գործիքները, որոնք հեշտ հասանելի են
                  մասնագետներին:
              </p>
          </div>
    },
    {
        id: 7,
        title: "Վիրաբուժական Ստոմատոլոգիա",
        imageSrc: require("../Assets/Images/Services/surgical_dentistry.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p>
                      <b>Վիրաբուժական ստոմատոլոգիա</b>
                  </p>
              </div>
              <br />
              <p>
                  Վիրաբուժական ստոմատոլոգիան այն ճյուզն է, որը հնարավորություն է տալիս մասնագետին կատարել
                  վիրահատություններ ատամների, փափուկ և կարծր հյուսվածքների շտկման համար։ Մեր բարձր որակավորում ստացած
                  վիրաբույժը պատրաստ է տրամադրել Ձեզ հետևյալ ծառայությունները`
              </p>
              <ul>
                  <li>Ատմաների հեռացում</li>
                  <li>Ռետենցված ատամներ հեռացում</li>
                  <li>Ատմաների արմատների վիրաբուժական հատում (ապեկոտոմիա)</li>
                  <li>Իմպլանտացիա և ոսկրային պլաստիկա</li>
              </ul>
              <br />
              <p>
                  Մեր փորձառու մասնագետի հետ բոլոր գործողությունները կատարվում են արագ և արդյունավետ՝ թույլ տալով
                  պացիենտին լինել հանգիստ այցելության ժամանակ։ Ժամանակակից սարքավորումների համադրությունը մեր վիրաբույժի
                  պրոֆեսիոնալիզմի հետ ապահովում են նույնիսկ բարդ դեպքերի հեշտ լուծում, նպաստելով հետվիրահատական
                  վերականգնման նվազագույն ժամկետներ պացիենտների համար։
              </p>
              <br />
              <p><b>Ատամնային իմպլանտացիա</b></p>
              <p>
                  Ստոմատոլոգիական իմպլանտատները իրենցից ներկայացնում են արհեստական արմատներ, որոնք տեղադրվում են
                  ծնոտների ոսկրի մեջ։ Իմպլանտատները կիրառելի են այն պացիենտների համար ովքեր կորցրել են մեկ և ավել
                  ատամներ։ Այս եղանակով հարևան առողջ ատամները չեն կիրառվում որպես հենասյուներ կամուրջներ պատրաստելիս
                  բացակա ատամի հատվածը վերականգնելու համար։
              </p>
              <p>
                  &emsp;
                  Իմպլանտատները նման են պտուտակի, որոնք տեղադրվում են ոսկրի հաստության մեջ։ Սովորաբար նրանք ցիլինդրիկ են
                  կամ ունեն արմատների ուրվագիծը։ Իմպլանտատները պատրաստված են տիտանից, որը կենսահամատեղելի է օրգանիզմի
                  հյուսվածքների հետ։ Իմպլանտատների մակերեսը անցնում է հատուկ մշակում ոսկրի հետ ավելի լավ սերտաճման
                  համար։ Իմպլանտատի տեղադրման և պատշաճ ոսկրի հետ սերտաճման դեպքում, իմպլանտատների հեռացման միակ եղանակը
                  իմպլանտատների շուրջ ոսկրի հեռացումն է։ Իմպլանտատների տեղադրումից հետո բավարար ոսկրագոյացման համար
                  անհրաժեշտ է սպասել որոշ ժամանակ՝ ստորին ծնոտի դեպքում 3ամիս, իսկ վերին ծնոտի 6 ամիս։ Այնուհետև
                  իմպլանտատներում տեղադրվում են աբաթմենթներ, որոնց վրա հետագայում ամրանում է պսակը, կամուրջը կամ
                  ցանկացած այլ կոնստրուկցիան։
              </p>
              <p>
                  Իմպլանտատների առավելություններն են՝
              </p>
              <ul>
                  <li>Հարմարավետություն</li>
                  <li>Սոսինձների կիրառման անհրաժեշտության բացակայություն</li>
                  <li>Կայունություն</li>
                  <li>Հարմարվելու կարճ ժամանակ</li>
                  <li>Սթրեսի/ճնշվածության նվազում կոնստրուկցիայի վրա</li>
                  <li>Բացակա հատվածի հարակից ատամների մշակման անհրաժեշտության բացակայություն</li>
                  <li>Հուսալի և երկարաժամկետ լուծում</li>
              </ul>
              <br />
              <p>
                  Կախված բացակա ատամների քանակից և ցանկալի վերջնական արդյունքից տեղադրվող իմպլանտատների քանակը կարող է
                  լիներ տարբեր։ Իմպլանտատները տեղադրվում են տեղային անզգայացման պայմաններում և պրոֆեսիոնալ մասնագետի
                  հսկողության տակ։ Տեղադրման ժամանակը կախված է իմպլանտատների քանակից ՝ 15-60 րոպե։ Ավլեին, մենք կարող
                  ենք վստահեցնել, որ իմպլանտատի տեղադրումը ավելի քիչ սթրեսային է և պահանջում է ավելի քիչ ժամանակ, քան
                  ատամի բուժումը։ Մեր պրոֆեսիոնալ վիրաբույժ-իմպլանտոլոգը կոնսուլտացիայի ժամանակ կներկայացնի Ձեզ
                  իմպլանտատների տեսակները և անհրաժեշտ քանակը Ձեր դեպքին համապատասխան։ Ձեր բոլոր անհանգստությունները
                  կփարատվեն մեր վիրաբույժ-իմպլանտոլոգի հետ զրույցից հետո, ստանալով բուժման քայլ առ քայլ պլանը։
              </p>
              <p>
                  &emsp;
                  Իմպլանտատները կարող են բարձրացնել Ձեր կյանքի որակը։ Եղեք համարձակ, կատարեք մեկ քայլ և վայելեք Ձեր
                  անկաշկանդ ժպիտը։
              </p>
          </div>
    },
    {
        id: 8,
        title: "Պերիօդոնտոլոգիա",
        imageSrc: require("../Assets/Images/Services/parontology.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p>
                      <b>Պերիօդոնտոլոգիա</b>
                  </p>
              </div>
              <br />
              <p>
                  Պերիօդոնտոլոգիան զբաղվում է շուրջատամնային հյուսվածքների հիվանդությունների բուժման և կանխարգելման
                  հարցերով: Փորցենք հանրամատչելի լեզվով ծանոթացնել որոշ բժշկական տերմինների և տեղեկացնենք բուժական
                  անհրաժեշտ միջոցառումների մասին:
              </p>
              <p>
                  Պերիօդոնտոլոգիայի հիմնական խնդիրներն են.
              </p>
              <ol>
                  <li>
                      Գինգիվիտ
                  </li>
                  <li>
                      Պերիօդոնտիտ
                  </li>
              </ol>
              <br />
              <ul style={{ marginLeft: "20px" }}>
                  <li>
                      Գինգիվիտը լնդաբորբի վաղ փուլն է. արտահայտվում է լնդային թեթև արյունահոսությամբ, քորով և քիչ քանակի
                      ատամնային նստվածքներով (ատամնաքար, ատամնափառ)
                  </li>
                  <li>
                      Պերիօդոնտիտը գինգիվիտի հաջորդ`երկրորդ փուլն է. բնորոշ են` կարծր ատամնային նստվածքներ, տհաճ հոտ,
                      այտուցված և արյունահոսող լնդեր, լնդագրպանիկներ որոնք բերում են ատամների շարժի:
                  </li>
              </ul>
              <br />
              <p>
                  Ամբողջ աշխարհում ավելի արդյունավետ է համարվում պրոֆիլակտիկան (կանխարգելումը) և բուժումը հիվանդության
                  վաղ փուլում, քան գանգատներ ունենալուց հետո հայտնաբերված հիվանդության բուժումը: Հատկապես զարգացած
                  երկրներում բժշկական ապահովագրության հիմնական պահանջն է պրոֆիլակտիկ զննումները, քանի որ ապահովագրական
                  ընկերության կամ պետության համար դա ավելի արդյունավետ և քիչ ծախսատար է, քան պատահաբար հայտնաբերված
                  հիվանդության բուժումը: Օրինակ` Գերմանիայում պացիենտը պարտավորվում է ինքնուրույն հոգալ բուժման ծախսերը,
                  եթե չի հետևել պարտադիր պրոֆիլակտիկ զննումների գրաֆիկին, ժամանակին չի այցելել բժիշկի ու հիվանդությունը
                  հայտնաբեվել է զարգանալուց, գանգատներ ունենալուց հետո միայն: Այդ պատճառով գերմանիայի բնակչությունը շատ
                  պարտաճանաչ, հստակ գրաֆիկով այցելում է բժիշկի` նույնիսկ գանգատներ չունենալու դեպքում:
              </p>
              <p>
                  Բացառություն չի հանդիսանում նաև մեր` ստոմատոլոգիայի ոլորտը: Մասնավորապես, պերիօդոնոտի խնդիրներից
                  խուսափելու համար կարող ենք տալ որոշ խորհուրդներ:
              </p>
              <br />
              <ol>
                  <li>
                      <p>
                          Ամենահեշտը գինգիվիտի կանխարգելումն է - անհրաժեշտ է տարին 1-2 անգամ բուժզննում անցկացնել,
                          հեռացնել
                          ատամնային նստվածքները: Այդ գործընթացը պացիենտից պահանջում է շատ քիչ ժամանակ և բավականին
                          արդյունավետ է` գինգիվիտից խուսափելու համար: Ջրի բաղադրության մեջ մտնող աղերը միշտ նստվածք են
                          թողնում թեյնիկի հատակին և մեզ համար նկատելի դառնում: Նույն ձևով թքի բաղադրության մեջ մտնող
                          աղերից
                          և ատամնափառից ձևավորվում է ատամնաքարը: Տարբեր մարդկանց մոտ այդ պրոցեսն ունի տարբեր
                          ինտենսիվություն` կախված պացիենտի հիգիենայից, թքի բաղադրությունից, նյութափոխանակությունից,
                          գենետիկական նախատրամադրվածությունից և այլ հանգամանքներից:
                      </p>
                      <p>
                          Նշենք, որ ատամնաքարերը լինում են վերլնդային և ստորլնդային: Վերջիններս աննկատ են մնում պացիենտի
                          աչքի համար, հայտնաբերվում են միայն գործիքով զննելիս, և եթե դուք համոզված եք, որ ատամնաքար
                          չունեք, քանի որ այն չեք տեսնում, դա դեռ չի նշանակում, որ դրանք չկան:
                      </p>
                  </li>
                  <li>
                      Պերիօդոնտիտը պահանջում է մի փոքր ավելի ուշադիր մոտեցում. Տարին 2 անգամ պարտադիր ատամնաքարերի
                      հեռացում, հարկ եղած դեպքում նաև դեղորայքային բուժում` ողողումների, ներքին ընդունման դեղերի և
                      տեղային քսուքների տեսքով:
                  </li>
              </ol>
              <br />
              <p>
                  Որպես վերոգրյալի ամփոփում` խորհուրդ ենք տալիս տարին 2 անգամ այցելել բժիշկ-ստոմատոլոգի, տեղեկանալ Ձեր
                  խնդիրներին և ժամանակին տալ դրանց անհրաժեշտ լուծումներ:
              </p>
              <p>
                  <b>Եղեք առողջ, ժպտացեք անկաշկանդ:</b>
              </p>
          </div>
    }
];

const servicesInfoEnglish = [
    {
        id: 0,
        title: "Consultations",
        imageSrc: require("../Assets/Images/Services/consultation_white.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p><b>Consultation</b></p>
                  <br />
              </div>
              <p>
                  What kind of expectation does the patient has attending dental consultation?
              </p>
              <p>
                  Seems like it is possible to describe the problem in couple minutes, get to know doctor’s opinion and
                  leave the cabinet
                  satisfied or somewhat satisfied. Let us understand the volume of the information the patient gets
                  attending dental clinic.
              </p>
              <ol>
                  <li>
                      Patient meets clinic’s administrator – who presents clinic’s order, helps to fill out medical
                      cars, organizes the appointments and deals with all organizational work.
                  </li>
                  <li>
                      Patient gets acquainted with general atmosphere of the clinic, medical personnel – people, with
                      the help of which all the medical interventions are being performed.
                  </li>
                  <li>
                      Patient is being informed about sanitary-hygienic condition of the clinic. If desired, with the
                      help of nurse a tour can be organized, during which the patient can have a closer look at the
                      process of the medical instruments, equipments disinfection and the operating rules.
                  </li>
                  <li>
                      Patient can look at the works done in the clinic through photos.
                  </li>
              </ol>
              <br />
              <p>
                  What are the steps of dental consultation?
              </p>
              <ol>
                  <li>
                      <p>
                          The patient describes his/her complaints in details, objective examination of the situation
                          and also all the required examinations are being performed, including X-ray diagnosis (OPTG),
                          computer tomography (CT) if necessary, diagnosis by intra-oral camera during which the patient
                          can see such details on the monitor which were not visible to the eye.
                      </p>
                      <p>
                          If needed the patient might undergo several extra examinations,
                      </p>
                  </li>
                  <li>
                      Professional photos are taken for the patient, during which he/she is looked at from different
                      perspectives. Those pictures are used for diagnosis, coming up with treatment plan, actual
                      treatment, additional diagnosis if needed, as well as for up-to-date evaluation.
                  </li>
                  <li>
                      Additional consultation is organized with the narrow specialist. A consultation can be conducted
                      by several specialists (implantologist, endodontist, orthodontist) at the same time.
                  </li>
              </ol>
              <br />
              <p>
                  &emsp;Complete medical consultation is the basis for offering a precise diagnosis and therefore a
                  reasonable treatment plan. After consultation an interclinical consilium is organized, where the
                  situation is being thoroughly discussed. Only after that the full treatment plan is offered to the
                  patient that considers the amount of work to be done, which are described in an order of importance
                  and necessity.
              </p>
              <p>
                  &emsp; It’s important to notice, that besides the main treatment plan an alternative option id offered
                  to the patient (econom). The first option includes innovative treatment technologies which is usually
                  more expensive. Both options include the price, and also the paying plan in sections.
              </p>
              <p>
                  &emsp;Very often, the patient knows only the smallest part of his/her problem. The doctor’s job is to
                  identify all the problems, which might seem to be minor but often have huge consequences.
              </p>
              <p>
                  &emsp;The human factor should be highlighted as well in the process of delivering the most important
                  and indispensable medical advice and generally in performing competent treatment. Of course, it is
                  very important to equip the clinic with state-of-the-art equipment, the use of innovative materials
                  and technologies, as the speed of development in the dental field is very high and modern high-level
                  professionalism requires continuous education and training. And only the doctor is the mediator that
                  can make this innovative work for the patient. It is the doctor who, using his years of experience,
                  knowledge, medical intuition, can offer and implement an appropriate treatment plan. The particular
                  important factor is the ability of the doctor to present the situation in detail in a language
                  understandable to the patient, bypassing professional terminology as far as possible. Not so less
                  important are personal attributes, the ability to hear and understand the other, the ability to
                  identify the patient's psychological and other characteristics.
              </p>
              <p>
                  &emsp;It is not accidental that one of the philosophers said: "If the patient's condition does not
                  ease after talking to the doctor, then he is not a doctor"
              </p>
          </div>

    },
    {
        id: 1,
        title: "Diagnostic Dentistry",
        imageSrc: require("../Assets/Images/Services/diagnostic_dentistry.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p>
                      <b>Diagnostic dentistry</b>
                  </p>
                  <br />
              </div>
              <ul>
                  <li>X-ray pictures</li>
                  <li>Diagnosis with interoral camera</li>
              </ul>
              <br />
              <p>
                  “Individuum” dental clinic by applying the basic principle of medicine treats not the disease but
                  patient, each person with an individual approach, each problem with an individual solution.
              </p>
          </div>
    },
    {
        id: 2,
        title: "Aesthetics",
        imageSrc: require("../Assets/Images/Services/aesthetics.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}><p><b>Aesthetics</b></p></div>
              <br />
              <p>With the growth of patients’ perceptions of having beautiful smile aesthetic dentistry developed
                  tremendously. Aesthetic dentistry is the combination of science and art. It is not a separate
                  discipline of dentistry; it combines all disciplines in one. Our team of professional is here to guide
                  you through the path of reaching the best outcome for you as an individuum from aesthetic, functional
                  and biological perspectives.
              </p>
              <p>
                  We are here to provide you individualized treatment according to your case. With the development of
                  aesthetic dentistry we offer you the following ways of recreating your natural smile:
              </p>
              <ul>
                  <li>Smile design</li>
                  <li>Teeth whitening</li>
                  <li>Professional cleaning and teeth polishing</li>
                  <li>Aesthetic teeth restoration (direct, indirect)</li>
                  <li>Veneers</li>
                  <li>Inlays, onlays</li>
              </ul>
              <br />
              <p><b> Teeth whitening</b></p>
              <p>
                  Our clinic is ready to provide you two types of teeth whitening systems: in the clinic and at home.
                  Both ways of teeth whitening are safe for your teeth enamel and the products used are approved by
                  American Dental Association. Products for both type of procedures contain hydrogen peroxide with
                  different concentration. The whitening procedure in the clinic is conducted by the dentist. It takes
                  approximately 60 minutes to complete the whitening process (depending your natural teeth shade and the
                  shade you want to achieve). For the home whitening procedure the dentist provides you individualized
                  loading trays and whitening gels with the instructions for usage.
              </p>
              <br />
              <p><b>Aesthetic teeth restoration (direct, indirect)</b></p>
              <p>
                  Direct aesthetic teeth restorations are widely spread type of restorations with the use of
                  composite/dental fillings. Direct restorations are done by our high qualified specialists who know the
                  nuances of the used fillings. Restorations are done by recreating teeth layers, their translucency,
                  forms and functions. Direct composite restorations are suitable for all teeth.
              </p>
              <p>
                  Indirect aesthetic teeth restorations are the branch of aesthetic dentistry including prosthetic
                  dental field. With the development of dentistry and patients’ perceptions zirconia crowns, veneers,
                  inlays and onlays are used for indirect teeth restorations. The details are provided below.
              </p>
              <br />
              <p><b>Veneers</b></p>
              <p>
                  Veneers are custom made thin shells that used for aesthetic restorations for improving teeth
                  appearance. Veneers can be made directly in the mouth with composite materials and indirectly in
                  dental laboratory using porcelain material. According to you individualizes case our dentist will help
                  you with the right choice of the veneer type. Veneers are usually used to fix
              </p>
              <ul>
                  <li>Discolored teeth</li>
                  <li>Teeth that are worn out</li>
                  <li>Chipped or broken teeth</li>
                  <li>Irregularly or unevenly aligned teeth</li>
                  <li>Teeth that have gaps between them</li>
              </ul>
              <p>
                  During the consultation our professional dentist will make a diagnosis and provide you the dental
                  treatment plan guiding you with the steps for having veneers.
              </p>
              <br />
              <p><b> Inlays and onlays</b></p>
              <p>
                  Usually too much damage of the teeth is considered as a contraindication for successful teeth
                  restoration using dental filling and having sufficient amount of healthy teeth for using crown. In
                  that case, inlays and onlyas are the ideal solution for recovering teeth appearance and function.
                  Inlays and onlays are made in dental laboratories from ceramic materials. The benefits of inlays and
                  onlays are the following:
              </p>
              <ul>
                  <li>Allow dentist to conserve more of the patient’s natural teeth material</li>
                  <li>They are durable – made of hard wearing materials</li>
                  <li>They strengthen teeth up to 75%</li>
                  <li>Prolong tooth life</li>
                  <li>Prevent extra/more dental treatment in the near future</li>
              </ul>
              <br />
              <p><b> Teeth whitening</b></p>
              <p>
                  Modern dentistry offers many ways of bleaching. Anyone who wants to have a flattering smile can find a
                  suitable and affordable whitening method. Professional cleaning of dental calculus and teeth plaque
                  can even change the color of your teeth, and with professional whitening you can make your smile
                  whiter. In any case, with the help of a dentist you can find the best option for you.
              </p>
              <ul>
                  <li>
                      <p>Office whitening</p>
                      <p>
                          The essence of the method is that teeth are covered with a bleaching agent that contains
                          active components. In advance soft tissues are protected to prevent burns. The active gel
                          stays on the teeth for 30-60 minutes, during which the active oxygen changes the color of the
                          enamel. The method is not indicated if there are numerous cracks in the teeth. The result of
                          the yellow enamel accessory will be more impressive than in the gray shade. It is possible to
                          repeat the procedure several times for the desired result.
                      </p>
                  </li>
                  <li>
                      <p>
                          Home whitening
                      </p>
                      <p>
                          For home bleaching, transparent and elastic bands are made in advance that are fully
                          compatible with the patient's teeth. The whitening gel is spread over the teeth evenly with
                          the aid of a individualized trays and stays there until the end of the procedure. Duration may
                          vary from 8 to 14 days depending on the method selected.
                      </p>
                  </li>
                  <li>
                      <p>
                          Laser whitening
                      </p>
                      <p>
                          After protecting the soft tissues, the teeth are coated with a bleaching agent. Activation of
                          the gel is done by laser beam, during which the active substances released and penetrate into
                          the deep layers of the enamel and dentin of the teeth and change their color. The procedure
                          lasts from 30 to 40 minutes.
                      </p>
                  </li>
              </ul>
              <br />
              <p>
                  It is important to know that prior to whitening it is essential to perform a complete oral cavity
                  repair, including gingival treatment if needed, replacement of old restorations, cavities. It is also
                  necessary to have a professional teeth calculus and plaque removal, which prepares the teeth for the
                  bleaching procedure. After teeth whitening the patient should avoid using colored food for couple of
                  days (wine, tea, coffee, colored fruits, berries).
              </p>
          </div>
    },
    {
        id: 3,
        title: "Teeth Whitening",
        imageSrc: require("../Assets/Images/Services/teeth_whitening.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p>
                      <b>Teeth whitening</b>
                  </p>
              </div>
              <br />
              <p>
                  Our clinic is ready to provide you two types of teeth whitening systems: in the clinic and at home.
                  Both ways of teeth whitening are safe for your teeth enamel and the products used are approved by
                  American Dental Association. Products for both type of procedures contain hydrogen peroxide with
                  different concentration. The whitening procedure in the clinic is conducted by the dentist. It takes
                  approximately 60 minutes to complete the whitening process (depending your natural teeth shade and the
                  shade you want to achieve). For the home whitening procedure the dentist provides you individualized
                  loading trays and whitening gels with the instructions for usage.
              </p>
              <p>
                  Modern dentistry offers many ways of bleaching. Anyone who wants to have a flattering smile can find a
                  suitable and affordable whitening method. Professional cleaning of dental calculus and teeth plaque
                  can even change the color of your teeth, and with professional whitening you can make your smile
                  whiter. In any case, with the help of a dentist you can find the best option for you.
              </p>
              <br />
              <ul>
                  <li>
                      <p>
                          Office whitening
                      </p>
                      <p>
                          The essence of the method is that teeth are covered with a bleaching agent that contains
                          active components. In advance soft tissues are protected to prevent burns. The active gel
                          stays on the teeth for 30-60 minutes, during which the active oxygen changes the color of the
                          enamel. The method is not indicated if there are numerous cracks in the teeth. The result of
                          the yellow enamel accessory will be more impressive than in the gray shade. It is possible to
                          repeat the procedure several times for the desired result.
                      </p>
                      <br />
                  </li>
                  <li>
                      <p>
                          Home whitening
                      </p>
                      <p>
                          For home bleaching, transparent and elastic bands are made in advance that are fully
                          compatible with the patient's teeth. The whitening gel is spread over the teeth evenly with
                          the aid of a individualized trays and stays there until the end of the procedure. Duration may
                          vary from 8 to 14 days depending on the method selected.
                      </p>
                      <br />
                  </li>
                  <li>
                      <p>
                          Laser whitening
                      </p>
                      <p>
                          After protecting the soft tissues, the teeth are coated with a bleaching agent. Activation of
                          the gel is done by laser beam, during which the active substances released and penetrate into
                          the deep layers of the enamel and dentin of the teeth and change their color. The procedure
                          lasts from 30 to 40 minutes.

                          It is important to know that prior to whitening it is essential to perform a complete oral
                          cavity repair, including gingival treatment if needed, replacement of old restorations,
                          cavities. It is also necessary to have a professional teeth calculus and plaque removal, which
                          prepares the teeth for the bleaching procedure. After teeth whitening the patient should avoid
                          using colored food for couple of days (wine, tea, coffee, colored fruits, berries).
                      </p>
                  </li>
              </ul>
          </div>
    },
    {
        id: 4,
        title: "Orthodontics",
        imageSrc: require("../Assets/Images/Services/orthodontics.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p>
                      <b>Orthodontics</b>
                  </p>
              </div>
              <br />
              <p>
                  Orthodontics is a branch of dentistry that deals with teeth and jaws that are positioned improperly.
                  The important part of orthodontic treatment is not only the choice of high qualified dentist but also
                  the proper time period of the future treatment. Another important factor for the best outcome is the
                  patient’s readiness to follow the dentist’s guides throughout the whole treatment period. Orthodontic
                  treatment might be necessary if you have:
              </p>
              <ul>
                  <li>
                      Crossbite
                  </li>
                  <li>
                      Crowding
                  </li>
                  <li>
                      Overjet
                  </li>
                  <li>
                      Disfiguring of the jaw
                  </li>
                  <li>
                      Breathing/swallowing problems
                  </li>
                  <li>
                      Missing or extra teeth
                  </li>
                  <li>
                      TMJ issues
                  </li>
              </ul>
              <p>
                  Our clinic can give you two well known options for orthodontic treatment:
              </p>
              <ul>
                  <li>
                      Traditional braces
                  </li>
                  <li>
                      Invisaligns
                  </li>
                  <li>
                      Appliances
                  </li>
              </ul>
              <br />
              <p><b> Traditional braces</b></p>
              <p>
                  Braces are the common type of orthodontic treatment provided by the majority of orthodontists.
                  Brackets are placed on the teeth connected with wire which allows tightening teeth or creating
                  movement. Usually two types of brackets are used: metallic and ceramic. When the appearance and the
                  aesthetic is the priority for the patient ceramic brackets can be used. Braces can be used at any ages
                  and when invisaligns are not the best option for the specific case.
              </p>
              <br />
              <p><b>Invisaligns</b></p>
              <p>
                  Very often people who need orthodontic treatment avoid using traditional braces for different reasons.
                  In this case with the development of medicine dentist offer Invisaligns as an option for orthodontic
                  treatment. Invisaligns consist of custom mage, invisible, plastic aligners that gradually straighten
                  teeth. Over the treatment period dentist provides new aligners after certain period of time.
              </p>
              <p>
                  Your fist visit and the consultation is an important part for the planning phase, during which the
                  dentist will let you know the best option for your individualized case.
              </p>
              <br />
              <p><b>Appliances</b></p>
              <p>
                  There are different types of appliances used both for children and grown-up. The professional
                  specialist can choose a right appliance for your case after consultation. They can be fixed or
                  removable, depending on the type and again the case of usage.
              </p>
          </div>
    },
    {
        id: 5,
        title: "Prosthetics",
        imageSrc: require("../Assets/Images/Services/prosthetics.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p>
                      <b>Prosthetics</b>
                  </p>
              </div>
              <br />
              <p>
                  Prosthetic dentistry is an area in dentistry that concentrates on aesthetic restoration and
                  replacement of missing teeth, soft and hard tissues to their proper look and function.
              </p>
              <p>
                  As an innovative dental clinic we offer to our patients’ wide range of prosthodontic treatment. In
                  order to restore your smile we can offer you the following dental prostheses:
              </p>
              <ul>
                  <li>
                      Fixed (crowns, bridges)
                  </li>
                  <li>
                      Removable (complete and partial dentures)
                  </li>
                  <li>
                      Prostheses fixed and supported on implants
                  </li>
              </ul>
              <br />
              <p><b>Crowns</b></p>
              <p>
                  Crowns are prostheses that help to restore your tooth’s shape and function. They are most often used
                  for teeth that are broken, worn out and destroyed by tooth decay. Compare crown to a “cap” that covers
                  your tooth. Crown materials vary according to materials’ aesthetics, physical functions and price. The
                  crowns can be made of metals, ceramic compounds such as porcelains or zirconia, and also combine both
                  materials (metal-ceramic crowns).
              </p>
              <br />
              <p><b>Bridges</b></p>
              <p>
                  Bridges are fixed prostheses which are usually used to replace one or two missing teeth. Dental
                  bridges are being cemented on teeth next to the missing part. This way the bridge is being fixed on
                  the natural teeth and replaces the missing part with artificial teeth. The bridges are made of metals,
                  ceramic compounds such as porcelains or zirconia, and also combine both materials (metal-ceramic
                  crowns).
              </p>
              <br />
              <p><b>Dentures</b></p>
              <p>
                  Complete dentures are made for patients who are edentulous (do not have any natural teeth). It can be
                  made for upper or lower jaw. Patients are able to remove their dentures when needed, usually for
                  carrying hygiene procedures. Complete removable dentures are made of acrylic resins which serve as a
                  base for artificial teeth. The artificial teeth are made of ceramic or acrylic materials.
              </p>
              <p>
                  Partial dentures are a treatment option for patients who have several natural teeth left on a
                  particular jaw. The dentist will let you know if your natural teeth can serve as a base for partial
                  dentures. These types of dentures are made of acrylic and metal materials with artificial teeth on
                  them. The metal frame connects to your natural teeth and feels in the missing part of the jaw.
              </p>
              <br />
              <p><b>Veneers</b></p>
              <p>
                  Veneers are custom made thin shells that used for aesthetic restorations for improving teeth
                  appearance. Veneers can be made directly in the mouth with composite materials and indirectly in
                  dental laboratory using porcelain material. According to you individualizes case our dentist will help
                  you with the right choice of the veneer type. Veneers are usually used to fix
              </p>
              <ul>
                  <li>Discolored teeth</li>
                  <li>Teeth that are worn out</li>
                  <li>Chipped or broken teeth</li>
                  <li>Irregularly or unevenly aligned teeth</li>
                  <li>Teeth that have gaps between them</li>
              </ul>
              <p>During the consultation our professional dentist will make a diagnosis and provide you the dental
                  treatment plan guiding you with the steps for having veneers.
              </p>
              <br />
              <p><b>Inlays and onlays</b></p>
              <p>
                  Usually too much damage of the teeth is considered as a contraindication for successful teeth
                  restoration using dental filling and having sufficient amount of healthy teeth for using crown. In
                  that case, inlays and onlyas are the ideal solution for recovering teeth appearance and function.
                  Inlays and onlays are made in dental laboratories from ceramic materials. The benefits of inlays and
                  onlays are the following:
              </p>
              <ul>
                  <li>Allow dentist to conserve more of the patient’s natural teeth material</li>
                  <li>They are durable – made of hard wearing materials</li>
                  <li>They strengthen teeth up to 75%</li>
                  <li>Prolong tooth life</li>
                  <li>Prevent extra/more dental treatment in the near future</li>
              </ul>
          </div>
    },
    {
        id: 6,
        title: "Therapeutic dentistry",
        imageSrc: require("../Assets/Images/Services/therapeutic_dentistry.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p>
                      <b> Therapeutic dentistry</b>
                  </p>
              </div>
              <br />
              <p>
                  One of the main branches of dentistry - therapeutic dentistry deals with the treatment and prevention
                  of dental diseases.
              </p>
              <br />
              <p>
                  Treatment, teeth restoration are a link of modern therapeutic dentistry, which includes all the
                  innovations and inventions of this field. The problem of therapeutic dentistry is the treatment of
                  teeth with any degree of deterioration and injury, such as caries, non-carious lesions and their
                  complications, treatment of stomatitis, fractures and any other injuries.
              </p>
              <p>
                  Therapeutic dentistry is now on such a level that can practically save any tooth. With the development
                  of endodontics, the indications for tooth removal are diminishing.
              </p>
              <p>
                  Endodontics deals with the treatment and restoration of tooth roots. Dental treatment will only be
                  considered successful if the tooth function is restored.
              </p>
              <br />
              <p>
                  Dental restoration is one of the main areas of therapeutic dentistry. Today, it is possible to
                  precisely restore the natural shape of the tooth, while still maintaining its natural color.
              </p>
              <p>
                  Nowadays, all modern materials and equipments are used and easily available for professionals.
              </p>
          </div>
    },
    {
        id: 7,
        title: "Surgical dentistry",
        imageSrc: require("../Assets/Images/Services/surgical_dentistry.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p>
                      <b> Surgical dentistry</b>
                  </p>
              </div>
              <br />
              <p>
                  Dental surgery is a special part of dentistry that allows the specialist to conduct surgeries of
                  teeth, gums and jaw bones. Our high qualified surgeon is here to provide you the following surgical
                  procedures:
              </p>
              <ul>
                  <li>Teeth extractions</li>
                  <li>Extractions of retained teeth</li>
                  <li>Surgical root surgery (apectomy)</li>
                  <li>Implant surgeries and bone graft</li>
              </ul>
              <br />
              <p>
                  With our experienced dental surgeon the procedures are done quickly and efficiently, allowing our
                  patient feel comfortable during the process. Combination of modern equipment and the professionalism
                  of our dentist mean that even complex cases are handled easily and competently letting our patients to
                  have minimal postsurgical recovery period.
              </p>
              <br />
              <p><b>Dental implantation</b></p>
              <p>
                  Dental implants are artificial roots placed in a jawbone. Dental implants are considered for patients
                  who have lost one or more teeth. This way the healthy teeth are not used as an anchor for creating
                  bridges to replace the missing tooth.
              </p>
              <p>
                  The implants are like screws inserted into the bone. Usually, they are cylindrical or root form. The
                  implants are made of titanium which is a biocompatible material. The surface of the implants is
                  modified for the better osseointegration. Once the implants are placed in the bone and the proper
                  osseointegration is completed around the implant, the only way to remove it is to drill the bone
                  around it. After placing the implants a certain amount of time is needed for the osseointegration. For
                  the lower jaw, it is 3 months and for the upper jaw, it is 6 months. Later then abutments are placed
                  into the implants which are going to hold the crown, bridge or the designed prosthesis.
              </p>
              <br />
              <p>
                  Implants have the following benefits:
              </p>
              <ul>
                  <li>More comfortable</li>
                  <li>No adhesives are required to use for dentures</li>
                  <li>Better stability</li>
                  <li>Short time for adaptation</li>
                  <li>Reduce the stress on the structure</li>
                  <li>Do not require modifying the adjacent teeth</li>
                  <li>Reliable and long term solution</li>
              </ul>
              <br />
              <p>
                  Depending on the number of missing teeth and the final outcome we want to reach the number of implants
                  can vary. Dental implants are placed under local anesthesia and the guidance of a qualified dentist.
                  The time span of implant placements varies, starting from 15 to 60 minutes depending on the number of
                  the implants. Moreover, we can say that implants placement is less stressful and takes less time than
                  tooth treatment. Our professional surgeon-implantologist will provide you detailed information about
                  the variety of implants and the proper solution for your specific case during the consultation. All
                  your fear will pass as you talk to our dentist-implantologist and get the step by step treatment plan.
              </p>
              <br />
              <p>
                  Implants can add value to your quality of life. Be brave, take a step and enjoy your fearless smile.
              </p>
          </div>
    },
    {
        id: 8,
        title: "Parontology",
        imageSrc: require("../Assets/Images/Services/parontology.svg"),
        description:
          <div>
              <div style={{ textAlign: "center" }}>
                  <p>
                      <b>Parodontology</b>
                  </p>
              </div>
              <br />
              <p>
                  Parodontology deals with the treatment and prevention of periosteal tissue diseases. Lets try to get
                  familiar with some of the medical terms in the public language and get information about the necessary
                  medical treatment.
              </p>
              <p>
                  The main aspects of parodontology are:
              </p>
              <ol>
                  <li>
                      Gingivitis
                  </li>
                  <li>
                      Parodontitis
                  </li>
                  <li>

                      Gingivitis is an early stage of gums inflammation process. It is characterized by mild gingival
                      bleeding, itching, and tooth decay (dental plaque and calculus)
                  </li>
                  <li>
                      Parodontitis is the next, second stage of gingivitis. It is characterized by hard tooth deposits,
                      unpleasant odor, swollen and bleeding gums, gingivitis that cause tooth movement.
                  </li>
              </ol>
              <br />
              <p>
                  Prevention and treatment in the early stages of the disease are considered to be more effective
                  worldwide than the disease found after complains. Especially in developed countries, the main
                  requirement of the medical insurances is preventive screening, as it is more effective and less costly
                  for the insurance company or the state than the treatment of accidentally discovered illness.
              </p>
              <p>
                  F or example, in Germany the patient is obliged to cover the costs of self-treatment if he / she does
                  not follow the schedule of mandatory prophylactic examinations, does not visit the doctor on time, and
                  the disease is discovered only after development, with complaints only. That is why the population of
                  Germany visits a doctor in a very conscientious, clear schedule, even in the absence of complaints.
              </p>
              <br />
              <p>
                  Our field of dentistry is no exception. In particular, we can offer some tips for avoiding periodontal
                  problems.
              </p>
              <ol>
                  <li>
                      <p>
                          The easiest is the prevention of gingivitis – you need to have dental examination 1-2 time a
                          year, remove dental plaque. This procedure requires very little time from the patient and is
                          effective enough to avoid gingivitis. The salts in the water always leave stains on the bottom
                          of the tea pot visible to us. In the same way, the salts from saliva leave plaque on teeth
                          producing dental calculus. This process is different in different people depending on the
                          patient's hygiene, saliva composition, metabolism, genetic predisposition and other
                          circumstances. It is important to point out the dental calculus can be above and under
                          gingiva. The latter remain unnoticed for the patient's eye, are only found when examined with
                          the use od instrument, and if you are sure that you do not have a calculus because you do not
                          see it, it does not mean that it does not exist.
                      </p>
                  </li>
                  <li>
                      Parodontitis requires a slightly more careful approach. Removal of dental calculus is mandatory
                      twice a year, if necessary also use of medication in the form of rashes, intravenous medications
                      and topical creams.
                  </li>
              </ol>
              <br />
              <p>
                  To summarize the above, we recommend that you visit a dentist twice a year, get acquainted with your
                  problems and provide timely solutions to them.
              </p>
          </div>
    }
];

export function getServicesInfo() {
    return servicesInfo;
}

export function getServicesInfoEnglish() {
    return servicesInfoEnglish;
}

